import { put, call, takeLatest } from "redux-saga/effects";
import { GetChannels } from "../../api/channelApi";
import {
  FETCH_CHANNELS,
  FETCH_CHANNELS_FAILED,
  FETCH_CHANNELS_SUCCESS
} from "../types/channels";

function* getChannels() {
  try {
    const channels = yield call(async () => {
      return await GetChannels();
    });
    yield put({ type: FETCH_CHANNELS_SUCCESS, channels: channels.data });
  } catch (e) {
    yield put({ type: FETCH_CHANNELS_FAILED, message: e });
  }
}

function* channel() {
  yield takeLatest(FETCH_CHANNELS, getChannels);
}

export default channel;
