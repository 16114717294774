const { call, put, takeLatest } = require("redux-saga/effects");
const { GetApps } = require("../../api/appApi");
const {
  FETCH_APPS_SUCCESS,
  FETCH_APPS_FAILED,
  FETCH_APPS
} = require("../types/apps");

function* getApps() {
  try {
    const apps = yield call(async () => {
      return await GetApps();
    });
    yield put({
      type: FETCH_APPS_SUCCESS,
      apps: apps.data
    });
  } catch (e) {
    yield put({ type: FETCH_APPS_FAILED, message: e });
  }
}

function* apps() {
  yield takeLatest(FETCH_APPS, getApps);
}

export default apps;
