import { put, call, takeLatest } from "redux-saga/effects";
import { GetCtlRules } from "../../api/ctlApi";
import {
  FETCH_CTL_RULES,
  FETCH_CTL_RULES_FAILED,
  FETCH_CTL_RULES_SUCCESS
} from "../types/ctlRules";

function* getCtlRules() {
  try {
    const ctlRules = yield call(async () => {
      return await GetCtlRules();
    });
    yield put({
      type: FETCH_CTL_RULES_SUCCESS,
      dataSources: ctlRules.data
    });
  } catch (e) {
    yield put({ type: FETCH_CTL_RULES_FAILED, message: e });
  }
}

function* ctlRules() {
  yield takeLatest(FETCH_CTL_RULES, getCtlRules);
}

export default ctlRules;
