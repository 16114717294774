import { put, call, takeLatest, select, delay } from "redux-saga/effects";
import {
  GetDataSources,
  GetDataSourcesCondtions,
  GetTemplateDataSources,
  GetAllDataSourceElements,
  GetForms,
  GetSearchDataSources
} from "../../api/dataSourceApi";
import {
  FETCH_DATA_SOURCE_CONDITIONS,
  FETCH_DATA_SOURCE_CONDITIONS_FAILED,
  FETCH_DATA_SOURCE_CONDITIONS_SUCCESS
} from "../types/dataSourceCondition";
import {
  FETCH_DATA_SOURCES,
  FETCH_DATA_SOURCES_FAILED,
  FETCH_DATA_SOURCES_SUCCESS,
  FETCH_FORMS,
  FETCH_FORMS_FAILED,
  FETCH_FORMS_SUCCESS,
  FETCH_SEARCH_DATA_SOURCES,
  FETCH_SEARCH_DATA_SOURCES_FAILED,
  FETCH_SEARCH_DATA_SOURCES_SUCCESS,
  FETCH_TEMPLATE_DATA_SOURCES,
  FETCH_TEMPLATE_DATA_SOURCES_ELEMENTS,
  FETCH_TEMPLATE_DATA_SOURCES_ELEMENTS_FAILED,
  FETCH_TEMPLATE_DATA_SOURCES_ELEMENTS_SUCCESS,
  FETCH_TEMPLATE_DATA_SOURCES_FAILED,
  FETCH_TEMPLATE_DATA_SOURCES_SUCCESS
} from "../types/dataSources";
import { OPEN_SNACKBAR } from "../types/snackbar";

function* getDataSources(action) {
  try {
    const state = yield select();
    const skipTable = state.dataSources.data.filter(d => d.type === "table")
      .length;
    const skipForm = state.dataSources.data.filter(
      d => d.type === "strive_form"
    ).length;
    const dataSources = yield call(async () => {
      return await GetDataSources(action.pageSize, skipTable, skipForm);
    });
    let tempDataSources = state.dataSources.data;
    tempDataSources = [...tempDataSources, ...dataSources.data.result];
    yield put({
      type: FETCH_DATA_SOURCES_SUCCESS,
      dataSources: tempDataSources,
      tableCount: dataSources.data.tableCount,
      formCount: dataSources.data.formCount,
      hasMore:
        Number(tempDataSources.length) <
        Number(dataSources.data.tableCount) + Number(dataSources.data.formCount)
    });
  } catch (e) {
    yield put({ type: FETCH_DATA_SOURCES_FAILED, message: e });
  }
}

function* getDataSourceConditions() {
  try {
    const dataSourceConditions = yield call(async () => {
      return await GetDataSourcesCondtions();
    });
    yield put({
      type: FETCH_DATA_SOURCE_CONDITIONS_SUCCESS,
      dataSourceConditions: dataSourceConditions.data
    });
  } catch (e) {
    yield put({ type: FETCH_DATA_SOURCE_CONDITIONS_FAILED, message: e });
  }
}

function* fetchTemplateDataSources(action) {
  try {
    const dataSources = yield call(async () => {
      return await GetTemplateDataSources(action.templateId);
    });
    yield put({
      type: FETCH_TEMPLATE_DATA_SOURCES_SUCCESS,
      templateDataSources: dataSources.data
    });
  } catch (e) {
    yield put({ type: FETCH_TEMPLATE_DATA_SOURCES_FAILED, message: e });
  }
}

function* fetchTemplateDataSourcesElements(action) {
  try {
    const elements = yield call(async () => {
      return await GetAllDataSourceElements(action.templateId, action.concatId);
    });
    yield put({
      type: FETCH_TEMPLATE_DATA_SOURCES_ELEMENTS_SUCCESS,
      dataSourceElements: elements.data
    });
  } catch (e) {
    yield put({
      type: FETCH_TEMPLATE_DATA_SOURCES_ELEMENTS_FAILED,
      message: e
    });
    yield put({
      type: OPEN_SNACKBAR,
      snackbarType: "error",
      message: "Error getting data source elements"
    });
  }
}

function* fetchForms(action) {
  try {
    yield delay(200);
    const forms = yield call(async () => {
      return await GetForms(action.filter, action.formId);
    });
    yield put({
      type: FETCH_FORMS_SUCCESS,
      searchedForms: forms.data.data
    });
  } catch (e) {
    yield put({ type: FETCH_FORMS_FAILED, message: e });
  }
}

function* fetchSearchedDataSource(action) {
  try {
    yield delay(200);
    const dataSources = yield call(async () => {
      return await GetSearchDataSources(action.filter);
    });
    yield put({
      type: FETCH_SEARCH_DATA_SOURCES_SUCCESS,
      searchedDataSources: dataSources.data.result
    });
  } catch (e) {
    yield put({ type: FETCH_SEARCH_DATA_SOURCES_FAILED, message: e });
  }
}

function* dataSource() {
  yield takeLatest(FETCH_DATA_SOURCES, getDataSources);
  yield takeLatest(FETCH_DATA_SOURCE_CONDITIONS, getDataSourceConditions);
  yield takeLatest(FETCH_TEMPLATE_DATA_SOURCES, fetchTemplateDataSources);
  yield takeLatest(
    FETCH_TEMPLATE_DATA_SOURCES_ELEMENTS,
    fetchTemplateDataSourcesElements
  );
  yield takeLatest(FETCH_FORMS, fetchForms);
  yield takeLatest(FETCH_SEARCH_DATA_SOURCES, fetchSearchedDataSource);
}

export default dataSource;
