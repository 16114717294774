import { put, call, takeLatest } from "redux-saga/effects";
import { GetDbTables } from "../../api/snippetApi";
import {
  FETCH_DB_TABLES,
  FETCH_DB_TABLES_FAILED,
  FETCH_DB_TABLES_SUCCESS
} from "../types/dbTables";

function* getDbTables() {
  try {
    const dbTables = yield call(async () => {
      return await GetDbTables();
    });
    yield put({ type: FETCH_DB_TABLES_SUCCESS, dbTables: dbTables.data });
  } catch (e) {
    yield put({ type: FETCH_DB_TABLES_FAILED, message: e });
  }
}

function* dbTable() {
  yield takeLatest(FETCH_DB_TABLES, getDbTables);
}

export default dbTable;
