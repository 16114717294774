import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  AddAsset,
  AddUser,
  GetAllAssets,
  GetAllUsers,
  GetAssets,
  GetUsers,
  UpdateAllUsers,
  UpdateAssets,
  UpdateUsers
} from "../../api/dlApi";
import { store } from "../../store";
import { updateDlProcedure } from "../actions/realTimeData";
import {
  ADD_ASSET,
  ADD_ASSET_FAILED,
  ADD_ASSET_SUCCEEDED,
  ADD_USER,
  ADD_USER_FAILED,
  ADD_USER_SUCCEEDED,
  FETCH_ALL_USERS,
  FETCH_ALL_USERS_FAILED,
  FETCH_ALL_USERS_SUCCEEDED,
  FETCH_ASSETS,
  FETCH_ASSETS_FAILED,
  FETCH_ASSETS_SUCCEEDED,
  FETCH_USERS,
  FETCH_USERS_FAILED,
  FETCH_USERS_SUCCEEDED,
  UPDATE_ASSETS,
  UPDATE_ASSETS_FAILED,
  UPDATE_ASSETS_SUCCEEDED,
  UPDATE_USERS,
  UPDATE_USERS_FAILED,
  UPDATE_USERS_SUCCEEDED,
  UPDATE_ALL_USERS,
  UPDATE_ALL_USERS_SUCCEEDED,
  UPDATE_ALL_USERS_FAILED,
  FETCH_ALL_ASSETS,
  FETCH_ALL_ASSETS_SUCCEEDED,
  FETCH_ALL_ASSETS_FAILED
} from "../types/dl";
import { SET_PRODUCT } from "../types/products";

function* getAllUsers() {
  try {
    const response = yield call(async () => {
      return await GetAllUsers();
    });
    yield put({
      type: FETCH_ALL_USERS_SUCCEEDED,
      allUsers: response.data
    });
  } catch (e) {
    yield put({ type: FETCH_ALL_USERS_FAILED, message: e });
  }
}

function* getUsers(action) {
  try {
    const response = yield call(async () => {
      return await GetUsers(action.productId);
    });
    yield put({
      type: FETCH_USERS_SUCCEEDED,
      users: response.data
    });
  } catch (e) {
    yield put({ type: FETCH_USERS_FAILED, message: e });
  }
}

function* addUser(action) {
  try {
    const state = yield select();
    const response = yield call(async () => {
      return await AddUser(action.user);
    });
    yield put({
      type: ADD_USER_SUCCEEDED,
      users: response.data.users
    });
    yield put({
      type: SET_PRODUCT,
      product: { ...state.products.product, hierarchy: response.data.hierarchy }
    });
  } catch (e) {
    yield put({ type: ADD_USER_FAILED, message: e });
  }
}

function* addAsset(action) {
  try {
    const response = yield call(async () => {
      return await AddAsset(action.asset);
    });
    yield put({
      type: ADD_ASSET_SUCCEEDED,
      assets: response.data
    });
  } catch (e) {
    yield put({ type: ADD_ASSET_FAILED, message: e });
  }
}

function* fetchAssets(action) {
  try {
    const response = yield call(async () => {
      return await GetAssets(action.productId);
    });
    yield put({
      type: FETCH_ASSETS_SUCCEEDED,
      assets: response.data
    });
  } catch (e) {
    yield put({ type: FETCH_ASSETS_FAILED, message: e });
  }
}

function* updateAssets(action) {
  try {
    yield call(async () => {
      return await UpdateAssets(action.assets);
    });
    const assets = store.getState().dl.assets;
    for (const asset of action.assets) {
      const index = assets.find(a => a.id === asset.id);
      assets[index] = asset;
    }
    yield put({
      type: UPDATE_ASSETS_SUCCEEDED,
      assets
    });
  } catch (e) {
    yield put({ type: UPDATE_ASSETS_FAILED, message: e });
  }
}

function* updateUsers(action) {
  try {
    const state = yield select();
    const response = yield call(async () => {
      return await UpdateUsers(action.users);
    });
    const users = store.getState().dl.users;
    for (const user of action.users) {
      const index = users.find(
        a =>
          a.userId === user.userId &&
          a.managerId === user.managerId &&
          a.assetId === user.assetId
      );
      users[index] = user;
    }
    yield put({
      type: UPDATE_USERS_SUCCEEDED,
      users
    });
    yield put(updateDlProcedure());
    yield put({
      type: SET_PRODUCT,
      product: { ...state.products.product, hierarchy: response.data.hierarchy }
    });
  } catch (e) {
    yield put({ type: UPDATE_USERS_FAILED, message: e });
  }
}

function* updateAllUsers(action) {
  try {
    yield call(async () => {
      return await UpdateAllUsers(action.users);
    });
    const allUsers = store.getState().dl.allUsers;
    const filteredAllUsers = store.getState().dl.filteredAllUsers;
    for (const user of action.users) {
      let index = allUsers.find(a => a.userId === user.id);
      if (index !== -1) allUsers[index] = user;
      index = filteredAllUsers.find(a => a.userId === user.id);
      if (index !== -1) filteredAllUsers[index] = user;
    }
    yield put({
      type: UPDATE_ALL_USERS_SUCCEEDED,
      allUsers,
      filteredAllUsers
    });
  } catch (e) {
    yield put({ type: UPDATE_ALL_USERS_FAILED, message: e });
  }
}

function* fetchAllAssets() {
  try {
    const allAssets = yield call(async () => {
      return await GetAllAssets();
    });
    yield put({
      type: FETCH_ALL_ASSETS_SUCCEEDED,
      allAssets: allAssets.data
    });
  } catch (e) {
    yield put({ type: FETCH_ALL_ASSETS_FAILED, message: e });
  }
}

function* dl() {
  yield takeLatest(FETCH_ALL_USERS, getAllUsers);
  yield takeLatest(FETCH_USERS, getUsers);
  yield takeLatest(ADD_USER, addUser);
  yield takeLatest(ADD_ASSET, addAsset);
  yield takeLatest(FETCH_ASSETS, fetchAssets);
  yield takeLatest(UPDATE_ASSETS, updateAssets);
  yield takeLatest(UPDATE_USERS, updateUsers);
  yield takeLatest(UPDATE_ALL_USERS, updateAllUsers);
  yield takeLatest(FETCH_ALL_ASSETS, fetchAllAssets);
}

export default dl;
